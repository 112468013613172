/* eslint-disable no-sequences */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaMapMarkerAlt, FaRegMap } from "react-icons/fa";
import { eventTypes } from "../venu-listing-form/overview/event-type-data";
import p1 from "../../assets/icons/venuPre1.svg";
import p2 from "../../assets/icons/venuPre2.svg";
import p3 from "../../assets/icons/catering/Catering.svg";
import p4 from "../../assets/icons/catering/external.svg";
import Tooltip from "../Tooltip";
import { useLocation } from "react-router-dom";
import { useUser } from "../../context/user";

const OverviewSection = ({ data, locationDetails }) => {
  const path = useLocation();
  const { editItem } = useUser();
  // Overview details pulled dynamically from data
  const getIconForEventType = (title) => {
    const eventType = eventTypes.find((type) => type.title === title);
    return eventType ? eventType.icon : null; // Return null if no match is found
  };
  const overviewDetails = [
    {
      icon: (
        <img
          className=""
          alt="data?.additionalDetails?.venueHire"
          src={getIconForEventType(data?.additionalDetails?.venueHire)}
        />
      ),
      label: "Private space",
      value: data?.additionalDetails?.venueHire,
    },
    {
      icon: <img src={p2} className="w-8 h-8" alt="standing" />,
      label: "Sitting",
      value: `Upto ${data?.capacity?.sitting || "N/A"} Sitting`,
    },
    {
      icon: <img src={p1} className="w-8 h-8" alt="standing" />,
      label: "Standing",
      value: `Upto ${data?.capacity?.standing || "N/A"} Standing`,
    },
    {
      icon:
        data?.cateringAndDrinks?.venueProvideInHouseCatering === false ? (
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src={p3}
              className="w-8 h-8 opacity-30"
              alt="Does not offer catering"
              style={{ position: "relative" }} // Image inline style
            />
            <div
              style={{
                position: "absolute",
                top: "-25px",
                left: "-25px",
                width: "150%",
                height: "150%",
                transform: "rotate(140deg)",
                borderTop: "2.5px solid #C2C2C2", // The diagonal line
              }}
            ></div>
          </div>
        ) : (
          <img src={p3} className="w-8 h-8" alt="Offers catering" />
        ),
      label: "Offers catering",
      value:
        data?.cateringAndDrinks?.venueProvideInHouseCatering === false
          ? "Offers no catering"
          : "Offers catering",
    },
    {
      icon:
        data?.cateringAndDrinks?.externalCatering === false ? (
          <img
            src="/cateringcross.svg"
            className=" size-[50px]"
            alt="Does not offer catering"
          />
        ) : (
          <img src={p4} className="w-8 h-8" alt="Outside catering" />
        ),
      label: "Outside catering",
      value:
        data?.cateringAndDrinks?.externalCatering === false
          ? "Outside catering not allowed"
          : "Outside catering allowed",
    },
  ];
  return (
    <div className="lg:w-3/4 w-full mx-auto">
      <div className="flex flex-col j mb-4">
        {data?.basicDetails?.venueName ? (
          <h2 className="text-[32px] font-bold flex gap-4 items-center">
            {data?.basicDetails?.venueName}
          </h2>
        ) : (
          <h2 className="text-[32px] font-medium text-[#717171] flex gap-4 items-center ">
            Venue Name
          </h2>
        )}

        <div className="text-[#717171] flex gap-2 items-center mb-4">
          <FaMapMarkerAlt className="inline mr-2" />
          {(path.pathname?.includes("restaurant-preview") &&
            editItem !== null) ||
          (path.pathname?.includes("create-Listing/Venue") &&
            editItem !== null) ||
          (path.pathname?.includes("venue-preview/Venue") &&
            editItem !== null) ? (
            <>
              {editItem?.location?.area || locationDetails.area || ""},
              {editItem?.location?.city || locationDetails.city || ""}
            </>
          ) : (
            <>
              <span className="font-medium underline decoration-2 underline-offset-2">
                {locationDetails?.city && locationDetails?.area
                  ? `${locationDetails?.city}, ${locationDetails?.area}`
                  : "Location Not Available"}
              </span>
            </>
          )}

          <a
            href="#map"
            className="text-blue-500 flex ml-1 font-semibold gap-2 items-center"
          >
            <FaRegMap />{" "}
            <span className="underline underline-offset-2 decoration-2">
              Show on map
            </span>
          </a>
        </div>
      </div>

      <h3 className="text-2xl font-bold py-4">Overview</h3>

      <div className="grid lg:grid-cols-5 grid-cols-2 gap-8 mb-4">
        {overviewDetails?.map((detail, index) => (
          <div
            key={index}
            className="h-[150px] w-[155px] relative flex flex-col px-8 py-5 gap-y-2 text-center  text-black items-center bg-[#F7F7F7] p-4 rounded-lg shadow-md justify-around"
          >
            <div>{detail.icon}</div>
            <div>
              <p
                className={`text-[10px] lg:text-[20px] text-center ${
                  detail.value === "Outside catering not allowed" ||
                  detail.value === "Offers no catering"
                    ? "text-[#717171]"
                    : "text-black"
                }`}
              >
                {detail.value === "Outside catering allowed" ||
                detail.value === "Outside catering not allowed"
                  ? "Outside catering"
                  : detail.value === "Offers no catering"
                  ? "Offers catering"
                  : detail?.value}
              </p>
            </div>
            {detail?.label === "Private space" && (
              <div className="absolute top-2 right-6">
                {detail?.value ? (
                  <Tooltip
                    text={
                      detail?.value === "Private"
                        ? "Private hire is a space within venue that is exclusively reserved for your event"
                        : detail?.value === "Whole venue"
                        ? "The entire venue is exclusively available for your event."
                        : detail?.value === "Semi-private"
                        ? "Semi-private hire involves booking a section of a venue that is partially secluded or separated, allowing for some privacy while other events or guests may also be present."
                        : detail?.value === "Shared space"
                        ? "A non-exclusive, common area with open access for everyone."
                        : ""
                    }
                  />
                ) : null}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OverviewSection;
