import React, { useContext, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import ToggleSwitch from "../../shared/common/custom-toggle";
import AddFeatureModal from "./add-feature-form";
import Modal from "../../shared/custom-modal/custom-modal";
import { VenueContext } from "../../../context/VenueContext";
import * as FaIcons from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import BanquetchairsImage from "../../../assets/icons/equipments/Banquetchairs.png";
import CocktailtablesImage from "../../../assets/icons/equipments/Cocktailtables.png";
import BarImage from "../../../assets/icons/equipments/bar.png";
import StagedecksImage from "../../../assets/icons/equipments/Stagedecks.png";
import DancefloorImage from "../../../assets/icons/equipments/Dancefloor.png";
import StoragespaceImage from "../../../assets/icons/equipments/Storagespace.png";

const defaultFeatures = [
  { name: "Banquet chairs", icon: BanquetchairsImage },
  { name: "Cocktail tables", icon: CocktailtablesImage },
  { name: "Bar", icon: BarImage },
  { name: "Stage decks", icon: StagedecksImage },
  { name: "Storage space", icon: StoragespaceImage },
  { name: "Dance floor", icon: DancefloorImage },
];

const EquipmentForm = () => {
  const { formData, setFormData } = useContext(VenueContext);
  const [features, setFeatures] = useState(defaultFeatures);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [featureToEdit, setFeatureToEdit] = useState(null);

  // Ensure equipment is always defined
  const equipmentFeatures = formData?.equipment?.equipment || {};
  const equipmentIcons = formData?.equipment?.equipmentIcons || {};

  useEffect(() => {
    const iconsMap = new Map(Object.entries(equipmentIcons));

    // Create the updated features array based on the current equipment features
    const updatedFeatures = Object.keys(equipmentFeatures).map((feature) => ({
      name: feature,
      icon: iconsMap.get(feature) || "FaAccusoft",
    }));

    setFeatures((prevFeatures) => {
      const existingFeaturesMap = new Map(
        prevFeatures.map((item) => [item.name, item])
      );

      // Check if new features are different from existing features
      const newFeatures = updatedFeatures.filter(
        (newFeature) => !existingFeaturesMap.has(newFeature.name)
      );

      // Only update the state if new features exist
      if (
        newFeatures.length > 0 ||
        prevFeatures.length !== updatedFeatures.length
      ) {
        return [...prevFeatures, ...newFeatures];
      }

      return prevFeatures;
    });
  }, [equipmentFeatures, equipmentIcons]);

  const handleToggle = (featureName) => {
    const updatedFeatures = {
      ...equipmentFeatures,
      [featureName]: !equipmentFeatures[featureName],
    };

    setFormData((prevData) => ({
      ...prevData,
      equipment: {
        ...prevData.equipment,
        equipment: updatedFeatures,
      },
    }));
  };

  const handleAddFeature = (newFeature) => {
    if (featureToEdit) {
      const updatedFeatures = features.map((feature) =>
        feature.name === featureToEdit.name ? newFeature : feature
      );
      setFeatures(updatedFeatures);
      setFeatureToEdit(null);
    } else {
      setFeatures((prevFeatures) => [...prevFeatures, newFeature]);
    }

    setFormData((prevData) => ({
      ...prevData,
      equipment: {
        ...prevData.equipment,
        equipmentIcons: {
          ...prevData.equipment?.equipmentIcons,
          [newFeature.name]: newFeature.icon,
        },
        equipment: {
          ...prevData.equipment.equipment,
          [newFeature.name]: true,
        },
      },
    }));
  };

  const handleUpdateFeature = (updatedFeature) => {
    // Update the features list
    const updatedFeatures = features.map((feature) =>
      feature.name === updatedFeature.name ? updatedFeature : feature
    );

    setFeatures(updatedFeatures);

    // Update form data
    const updatedRoomFeatures = { ...equipmentFeatures };
    const updatedRoomFeaturesIcons = {
      ...formData.amenities?.equipmentIcons,
    };

    updatedRoomFeatures[updatedFeature.name] = updatedFeature.icon;
    updatedRoomFeaturesIcons[updatedFeature.name] = updatedFeature.icon;

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        roomFeatures: updatedRoomFeatures,
        roomFeaturesIcons: updatedRoomFeaturesIcons,
      },
    }));

    setFeatureToEdit(null);
  };

  const handleEditFeature = (feature) => {
    setFeatureToEdit(feature);
    setIsModalOpen(true);
  };

  const handleDeleteFeature = (featureName) => {
    const updatedFeatures = features.filter(
      (feature) => feature.name !== featureName
    );
    setFeatures(updatedFeatures);

    const updatedEquipmentFeatures = { ...equipmentFeatures };
    delete updatedEquipmentFeatures[featureName];

    setFormData((prevData) => ({
      ...prevData,
      equipment: {
        ...prevData.equipment,
        equipment: updatedEquipmentFeatures,
      },
    }));
  };

  // const renderIcon = (iconName) => {
  //   const IconComponent = FaIcons[iconName];
  //   return IconComponent ? <IconComponent /> : null;
  // };
  // this function is used for the render the images instead of icons
  // const renderIcon = (iconSrc) => {
  //   return <img src={iconSrc} alt="feature-icon" className="w-8 h-8" />;
  // };

  const renderIcon = (iconName) => {
    const IconComponent = FaIcons[iconName];
    return IconComponent ? <IconComponent /> : null;
  };

  return (
    <div className="">
      <div className="flex">
        <div className="w-full px-4">
          <div className="flex justify-between items-center w-full pb-5">
            <h2 className="text-3xl font-bold mb-4">Equipment</h2>
            <button
              onClick={() => setIsModalOpen(true)}
              className="text-primary font-medium"
            >
              Add other Equipment
            </button>
          </div>
          {features &&
            features.map((feature, index) => {
              const isDefaultFeature = defaultFeatures.find(
                (defaultFeature) => defaultFeature.icon === feature.icon
              );

              return (
                <div
                  key={index}
                  className="flex py-2 items-center justify-between lg:w-1/2 w-full mb-4"
                >
                  <div className="flex items-center">
                    <span className="text-2xl mr-2">
                      {isDefaultFeature ? (
                        // <span className="text-2xl mr-2">{feature.icon}</span>
                        <img src={feature.icon} alt="" className="pr-4" />
                      ) : (
                        <span className="text-2xl mr-2 pr-4 flex items-center">
                          {renderIcon(feature.icon)}
                        </span>
                      )}
                      {/* Render image instead of icon */}
                    </span>
                    <span className="text-base font-medium text-black truncate max-w-[14rem]">
                      {feature.name}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 relative">
                    <ToggleSwitch
                      isOn={equipmentFeatures[feature.name] || false}
                      handleToggle={() => handleToggle(feature.name)}
                    />
                    {isDefaultFeature ? (
                      ""
                    ) : (
                      <div className="flex items-center gap-2 absolute left-16">
                        <FaTrash
                          className="cursor-pointer text-red-600"
                          onClick={() => handleDeleteFeature(feature.name)}
                        />
                        <CiEdit
                          className="cursor-pointer text-secondary"
                          onClick={() => handleEditFeature(feature)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddFeatureModal
          onSave={handleAddFeature}
          onRequestClose={() => setIsModalOpen(false)}
          featureToEdit={featureToEdit}
          onUpdate={handleUpdateFeature}
        />
      </Modal>
    </div>
  );
};

export default EquipmentForm;
