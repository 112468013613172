import React, { useEffect, useState } from "react";
import { Box, Container, Typography, Button, useTheme } from "@mui/material";
import BillingInformationBox from "./BillingInformationBox";
import BillingAddressBox from "./BillingAddressBox";
import { toast } from "react-toastify";
import { MdScatterPlot } from "react-icons/md";

const BillingForm = ({
  handleContinue,
  shouldHandlePayment,
  setBillingInformation,
  loading,
}) => {
  const [trn, setTrn] = useState("");
  const [startLoading, setStartLoading] = useState(false);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [poBox, setPoBox] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [country, setCountry] = useState("");
  const theme = useTheme();
  const [isFormValid, setIsFormValid] = useState({
    billingInfo: false,
    billingAddress: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  const handleFormValidation = (component, isValid) => {
    setIsFormValid((prev) => ({
      ...prev,
      [component]: isValid,
    }));
  };

  function checkTrnAndContinue() {
    setStartLoading(true);
    if (isFormValid.billingInfo === false) {
      toast.error("Please fill billing information");
      return;
    } else if (isFormValid.billingAddress === false) {
      toast.error("Please fill billing address");
      return;
    }
    if (trn && trn.length !== 10) {
      toast.error("Trn Length should be 10 and only numbers");
      return;
    }
    setBillingInformation({
      trn,
      addressLine1,
      addressLine2,
      poBox,
      city,
      area,
      country,
    });
    handleContinue(shouldHandlePayment);
    setStartLoading(false);
  }
  const isValidForm = isFormValid.billingInfo && isFormValid.billingAddress;

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ mt: 4, textAlign: "center", mb: 3 }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "550" }}
        >
          Billing Information 
        </Typography>
      </Box>
      <BillingInformationBox
        handleFormValidation={(isValid) =>
          handleFormValidation("billingInfo", isValid)
        }
        trn={trn}
        setTrn={setTrn}
        setBillingInformation={setBillingInformation}
      />
      <BillingAddressBox
        handleFormValidation={(isValid) =>
          handleFormValidation("billingAddress", isValid)
        }
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        poBox={poBox}
        city={city}
        area={area}
        country={country}
        setAddressLine1={setAddressLine1}
        setAddressLine2={setAddressLine2}
        setPoBox={setPoBox}
        setCity={setCity}
        setArea={setArea}
        setCountry={setCountry}
        setBillingInformation={setBillingInformation}
      />
      <Box
        sx={{
          mt: 6,
          mb: 8,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <>
            <button className="bg-gray-400 flex gap-x-3 justify-center items-center  active:opacity-55  text-white p-2 rounded-lg w-full md:w-[400px] ">
              Processing <MdScatterPlot className=" animate-spin" />
            </button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: isValidForm
                  ? "#fe4847"
                  : theme.palette.grey.border,
                borderRadius: "10px",
                textTransform: "none",
                width: { xs: "100%", md: "60%" },
                "&:hover": {
                  backgroundColor: isValidForm
                    ? "darkred"
                    : theme.palette.grey.border,
                },
              }}
              onClick={checkTrnAndContinue}
              disabled={!isValidForm}
            >
              Continue
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default BillingForm;
