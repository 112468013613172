/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import auto from "../../assets/icons/auto.svg";
import { useLocation } from "react-router-dom";
import { VenueContext } from "../../context/VenueContext";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getVendorHotel } from "../../api/hotel-api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { useUser } from "../../context/user";

const CardPreview = () => {
  const { editItem } = useUser();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["loggedin-user"]);
  const { formData } = useContext(VenueContext);
  const [minSpendPricing, setMinSpendPricing] = useState(null);
  const [minSpendPerPerson, setMinSpendPerPerson] = useState(null);
  const [venueRental, setVenueRental] = useState(null);
  const [locationDetails, setLocationDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    area: "",
    poBox: "",
    country: "",
    mapLink: "",
  });
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = formData?.imagesAndVideos?.images || [];
  const vendorId = localStorage?.getItem("vendorId");

  const { data: hotel, isPending } = useQuery({
    queryKey: ["vendor-hotel"],
    queryFn: async () => {
      if (
        user?.onBoardingDetails?.type === "HotelStayandVenue" &&
        path.pathname?.includes("venu-Listing-Form")
      ) {
        const res = await getVendorHotel(vendorId);
        setLocationDetails({
          ...locationDetails,
          addressLine1: res?.overview?.locationDetails?.addressLine1,
          addressLine2: res?.overview?.locationDetails?.addressLine2,
          city: res?.overview?.locationDetails?.city,
          area: res?.overview?.locationDetails?.area,
          poBox: res?.overview?.locationDetails?.poBox,
          country: res?.overview?.locationDetails?.country,
          mapLink: res?.overview?.locationDetails?.mapLink,
        });
        return res;
      } else {
        setLocationDetails({
          ...locationDetails,
          addressLine1: formData?.location?.addressLine1,
          addressLine2: formData?.location?.addressLine2,
          city: formData?.location?.city,
          area: formData?.location?.area,
          poBox: formData?.location?.poBox,
          country: formData?.location?.country,
          mapLink: formData?.location?.mapLink,
        });
        return true;
      }
    },
  });

  const calculateMinSpendFromSelectedOptions = () => {
    if (formData?.pricing?.selectedOptions?.length) {
      let minSpendPricing = null;
      let venueRental = null;
      let minSpendPerPerson = null;
      let hasMinimumSpendPricing = false;

      formData.pricing.selectedOptions.forEach((option) => {
        const minSpendValue = Number(option.details.minSpend);

        switch (option.type) {
          case "Minimum Spend Pricing":
            hasMinimumSpendPricing = true; // Flag that "Minimum Spend Pricing" exists
            minSpendPricing =
              minSpendPricing === null
                ? minSpendValue
                : Math.min(minSpendPricing, minSpendValue);
            break;

          case "Venue Rental":
            // Only use Venue Rental if Minimum Spend Pricing isn't available
            venueRental =
              venueRental === null
                ? minSpendValue
                : Math.min(venueRental, minSpendValue);
            break;

          case "Min spend per person":
            minSpendPerPerson =
              minSpendPerPerson === null
                ? minSpendValue
                : Math.min(minSpendPerPerson, minSpendValue);
            break;

          default:
            break;
        }
      });

      // Set the calculated minimum spend values to the state
      setMinSpendPricing(minSpendPricing);
      setMinSpendPerPerson(minSpendPerPerson);
      setVenueRental(venueRental);
    } else {
      // If no selected options are present, reset all values to null
      setMinSpendPricing(null);
      setMinSpendPerPerson(null);
      setVenueRental(null);
    }
  };

  // Call this function in useEffect to update the values whenever formData changes
  useEffect(() => {
    calculateMinSpendFromSelectedOptions();
  }, [formData]);

  // Fallback image in case no image is provided
  const imageSrc =
    formData?.imagesAndVideos?.images.length > 0
      ? formData?.imagesAndVideos?.images[0]
      : "";

  // Destructure formData to simplify access
  const { basicDetails } = formData || {};

  const hotelName =
    basicDetails?.venueName.charAt(0).toUpperCase() +
      basicDetails?.venueName.slice(1) || "Vennue Name Not Available";
  const hotelDescription =
    basicDetails?.venueDescription || "Description Not Available";
  const location =
    formData.location?.city || formData.location?.area
      ? `${formData.location?.city}, ${formData.location?.area}`
      : "Location Not Available";

  const path = useLocation();
  const navigate = useNavigate();

  const CustomSkeleton = () => (
    <div className="w-full h-[300px] lg:w-[300px] rounded-lg bg-gray-200 animate-pulse"></div>
  );

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
  };
  return (
    <div className="bg-white rounded-lg w-full   ">
      <h2 className="text-3xl font-bold mb-6">Card Preview</h2>
      <div className="rounded-lg overflow-hidden shadow-lg mb-4 lg:w-[300px]">
        <div className="relative z-[1000px] w-full h-[190px] overflow-hidden rounded-lg">
          {images.length > 0 ? (
            <>
              {/* Image container */}
              <div className="w-full h-full">
                <img
                  src={images[currentSlide]}
                  alt={`Slide ${currentSlide}`}
                  className="w-full h-full object-contain rounded-t-lg"
                />
              </div>

              {/* Custom Prev Arrow */}
              <div
                className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50"
                onClick={handlePrev}
              >
                <SlArrowLeft className="text-[#000]" />
              </div>

              {/* Custom Next Arrow */}
              <div
                className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-50"
                onClick={handleNext}
              >
                <SlArrowRight className="text-[#000]" />
              </div>
            </>
          ) : (
            <CustomSkeleton />
          )}
        </div>
        <div className="p-4 text-[#5E5E5E]">
          <h3 className="text-xl font-medium">
            {hotelName},&nbsp;
            {formData?.basicDetails?.hotelName.charAt(0).toUpperCase() +
              formData?.basicDetails?.hotelName.slice(1)}
          </h3>
          {(path.pathname?.includes("restaurant-preview") &&
            editItem !== null) ||
          (path.pathname?.includes("create-Listing/Venue") &&
            editItem !== null) ||
          (path.pathname?.includes("venue-preview/Venue") &&
            editItem !== null) ? (
            <>
              <p>
                {editItem?.location?.area || locationDetails.area || ""},
                {editItem?.location?.city || locationDetails.city || ""}
              </p>
            </>
          ) : (
            <>
              <span className="font-medium underline decoration-2 underline-offset-2">
                {locationDetails?.city && locationDetails?.area
                  ? `${locationDetails.city}, ${locationDetails.area}`
                  : "Location Not Available"}
              </span>
            </>
          )}

          <div className="flex items-center my-2">
            {/* <LiaChairSolid className='mr-1 text-2xl text-primary' /> */}
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2272_13995)">
                <path
                  d="M15.0644 10.2681V0.5H13.8925V1.75015H6.10748V0.5H4.93561V10.2681C4.24789 10.6965 3.78906 11.4592 3.78906 12.3274V20.5H4.96094V16.1075H15.0389V20.5H16.2108V12.3272C16.2108 11.4592 15.752 10.6965 15.0644 10.2681ZM4.96094 12.3272C4.96094 11.6363 5.52307 11.0742 6.21399 11.0742H13.7859C14.4768 11.0742 15.0389 11.6363 15.0389 12.3272V12.6655H4.96094V12.3272ZM13.8925 6.41217H6.10748V2.92203H13.8925V6.41217ZM6.10748 7.58405H13.8925V9.90506C13.8571 9.90353 13.8217 9.90231 13.7859 9.90231H6.21399C6.17828 9.90231 6.14288 9.90353 6.10748 9.90506V7.58405ZM15.0389 14.9356H4.96094V13.8374H15.0389V14.9356Z"
                  fill="#FE4747"
                />
              </g>
              <defs>
                <clipPath id="clip0_2272_13995">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>

            <span className="mr-3">{formData?.capacity?.sitting || "-"}</span>
            {/* <IoPeopleSharp className='mr-1 text-2xl text-primary' />
             */}
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5 6.5C17.89 7.2 14.83 7.5 12 7.5C9.17 7.5 6.11 7.2 3.5 6.5L3 8.5C4.86 9 7 9.33 9 9.5V22.5H11V16.5H13V22.5H15V9.5C17 9.33 19.14 9 21 8.5L20.5 6.5ZM12 6.5C13.1 6.5 14 5.6 14 4.5C14 3.4 13.1 2.5 12 2.5C10.9 2.5 10 3.4 10 4.5C10 5.6 10.9 6.5 12 6.5Z"
                fill="#FE4747"
              />
            </svg>

            <span>{formData?.capacity?.standing || "-"}</span>
          </div>
          <div className="flex items-center my-2 gap-2">
            <img src={auto} alt="" />
            <span className="mr-3">{formData?.venueType?.venueRepresent}</span>
          </div>
          <div className="flex justify-between gap-5">
            {/* Case 1: Both minSpendPricing and venueRental available */}
            {minSpendPricing &&
            venueRental &&
            !Number.isNaN(minSpendPricing) &&
            !Number.isNaN(venueRental) ? (
              <>
                <div className="">
                  <p className="text-primary font-medium">
                    AED {minSpendPricing}
                  </p>
                  {/* Show one "Min. spend" text */}
                  <p className="text-sm">Min. spend</p>
                </div>
                {/* <div className="">
                  <p className="text-primary font-medium">AED {venueRental}</p>
                </div> */}
              </>
            ) : (
              <>
                {/* Case 2: Both minSpendPerPerson and venueRental available */}
                {minSpendPerPerson &&
                venueRental &&
                !Number.isNaN(minSpendPerPerson) &&
                !Number.isNaN(venueRental) ? (
                  <>
                    <div className="">
                      <p className="text-primary font-medium">
                        AED {venueRental}
                      </p>
                      <p className="text-sm">Min. spend</p>
                    </div>
                    <div className="">
                      <p className="text-primary font-medium">
                        AED {minSpendPerPerson}
                      </p>
                      <p className="text-sm">Per guest</p>
                    </div>
                  </>
                ) : (
                  <>
                    {/* Render each one individually if other cases don't match */}
                    {minSpendPerPerson && !Number.isNaN(minSpendPerPerson) && (
                      <div className="">
                        <p className="text-primary font-medium">
                          AED {minSpendPerPerson}
                        </p>
                        <p className="text-sm">Per guest</p>
                      </div>
                    )}
                    {minSpendPricing && !Number.isNaN(minSpendPricing) && (
                      <div className="">
                        <p className="text-primary font-medium">
                          AED {minSpendPricing}
                        </p>
                        <p className="text-sm">Min. spend</p>
                      </div>
                    )}
                    {venueRental && !Number.isNaN(venueRental) && (
                      <div className="">
                        <p className="text-primary font-medium">
                          AED {venueRental}
                        </p>
                        <p className="text-sm">Min. spend</p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPreview;
